/* ArtOrAiGame.css */

/* Main container styles */
.art-or-ai-game {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Game title styles */
  .game-title {
    font-size: 2.5rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Image container styles */
  .image-container {
    position: relative;
    width: 100%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .image-container img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Round information styles */
  .round-info {
    text-align: center;
    font-size: 1.2rem;
    margin: 15px 0;
  }
  
  /* Button container styles */
  .button-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }

  /* Custom button styles (complementing shadcn/ui) */
  .custom-button {
    transition-duration: 0.4s;
    background-color: white;
    color: black;
    border: 2px solid royalblue !important; /* This already adds a border */
    border-radius: 10px; /* Optional, if you want rounded borders */
    padding: 10px 24px;
  }

  .custom-button:hover {
    transform: scale(1.05);
    background-color: royalblue;
    color: white;
  }
  
  /* Game over screen styles */
  .game-over {
    text-align: center;
  }
  
  .final-score {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 20px 0;
  }
  
  /* Results table styles */
  .results-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
  
  .results-table th,
  .results-table td {
    padding: 10px;
    text-align: left;
  }
  
  .results-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .results-table td img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  /* Responsive design */
  @media (max-width: 640px) {
    .game-title {
      font-size: 2rem;
    }
  
    .button-container {
      flex-direction: column;
      gap: 10px;
    }
  
    .results-table th,
    .results-table td {
      padding: 8px;
      font-size: 0.9rem;
    }
  }